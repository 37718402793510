<template>
	<section class="banner-box" v-if="bannerList.list.length">
		<el-carousel height="37vw">
			<el-carousel-item v-for="(item, index) in bannerList.list" :key="index">
				<div class="banner-text">
					<h3 class="banner-title" v-html="item.title"></h3>
					<p class="banner-desc over-text4" v-html="item.text"></p>
					<div class="custom-btn" v-if="item.buttonText" @click="handleUrl(item.url)">
						{{ item.buttonText }}
						<el-icon size="18" style="position: relative; top:2px; margin-left: 5px;">
							<ArrowRight />
						</el-icon>
					</div>
				</div>
				<img class="banner-img" :src="item.img" alt="" @click="handleUrl(item.url)">
			</el-carousel-item>
		</el-carousel>
	</section>
</template>
<script setup>
	import {
		ref,
		reactive,
		computed,
		defineProps,
		toRefs
	} from 'vue'
	import {
		queryBannerSelect
	} from '@/api/publish'
	import {
		useRouter
	} from 'vue-router'
	// 首页轮播图 1
	// 大赛介绍 2
	// 证书查询 3
	// 作品展示 4
	// 往届回顾 5
	// 专家库 6
	// 联系我们 7
	// 关于我们 8
	// 赛道详情 9
	const router = useRouter()

	function toPage(url) {
		if (url) {
			router.push(url)
		}
	}
	const props = defineProps({
		list: {
			type: Array,
			default: () => ([])
		},
		position: {
			type: Number,
			default: null
		}
	})
	const {
		list,
		position
	} = toRefs(props)
	const bannerList = reactive({
		list: []
	})
	queryBannerSelect({
		page: 1,
		size: 10,
		position: position.value,
		type: 0
	}).then(res => {
		bannerList.list = res.data.data
	})

	function handleUrl(url) {
		if (url) {
			window.open(url, '_blank')
		}
	}
</script>
<style lang='scss' scoped>

</style>
