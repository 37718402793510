import {
	createApp
} from 'vue'
import {
	createPinia
} from 'pinia'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import {
	ArrowRight,
	ArrowRightBold
} from '@element-plus/icons'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import BannerStyle1 from '@/components/BannerStyle1'
import 'element-plus/dist/index.css'
import '@/utils/mobile'

const app = createApp(App)
app.component('ArrowRight', ArrowRight)
app.component('ArrowRightBold', ArrowRightBold)
app.component('BannerStyle1', BannerStyle1)
app.use(ElementPlus, {
	locale
}).use(createPinia()).use(router).mount('#app')
