<template>
	<headerBox v-if="!currentMeta.footer"></headerBox>
	<main class="main">
		<router-view v-slot="{ Component }">
			<keep-alive :include="['home']">
				<component :is="Component" />
			</keep-alive>
		</router-view>
	</main>
	<footerBox v-if="!currentMeta.footer"></footerBox>
</template>
<script setup>
	import headerBox from './header.vue'
	import footerBox from './footer.vue'
	import BannerStyle1 from '@/components/BannerStyle1'
	import {
		useRoute
	} from 'vue-router'
	import {
		reactive,
		ref,
		watch,
	} from 'vue';
	const route = useRoute()
	let currentMeta = ref("")
	const state = reactive({
		includeList: []
	})

	watch(
		() => route,
		(val) => {
			currentMeta.value = val.meta
			if (val.meta.keepAlive && state.includeList.indexOf(val.name) === -1) {
				state.includeList.push(val.name);
			}
		}, {
			immediate: true,
			deep: true
		}
	);
</script>
<style lang='scss' scoped>
	.main {
		margin: 0 auto;
		padding-top: $headerHeight;
	}
</style>
