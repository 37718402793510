import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import layout from '@/Layout/index'
import { getToken } from '@/utils/auth'

/** 
 * header 头部 footer 底部 为true则隐藏，默认false
 */

const nav = {
  path: '/',
  component: layout,
  children: [{
    path: "",
    name: 'home',
    component: () => import('@/views/nav/home.vue'),
    meta: {
      name: "首页",
      keepAlive: true
    }
  },
  {
    path: '/nav/details',
    name: 'details',
    component: () => import('@/views/nav/details.vue'),
    meta: {
      name: "大赛介绍"
    }
  },
  ]
};


const dynamic = {
  path: '/dynamic',
  component: layout,
  children: [{
    path: '',
    name: 'dynamicHome',
    component: () => import('@/views/event-dynamic/index.vue'),
    meta: {
      name: "赛事动态"
    }
  }]
}

const certificate = {
  path: '/certificate',
  component: layout,
  children: [{
    path: '',
    name: 'certificateHome',
    component: () => import('@/views/certificate/index.vue'),
    meta: {
      name: "证书查询"
    }
  }]
}

const work = {
  path: '/work',
  component: layout,
  children: [{
    path: 'index',
    name: 'workIndex',
    component: () => import('@/views/work/index.vue'),
    meta: {
      name: "作品展示"
    }
  }, {
    path: 'detail',
    name: 'workdetail',
    component: () => import('@/views/work/work-detail/workDetail.vue'),
    meta: {
      name: "作品详情"
    }
  }]
}

const expert = {
  path: '/expert',
  component: layout,
  children: [{
    path: 'index',
    name: 'expertxpert',
    component: () => import('@/views/expert/index.vue'),
    meta: {
      name: "专家库"
    }
  }, {
    path: 'detail',
    name: 'expertdetail',
    component: () => import('@/views/expert/expert-detail/expertDetail.vue'),
    meta: {
      name: "专家详情"
    }
  },]
}

const about = {
  path: '/about',
  component: layout,
  children: [{
    path: '',
    name: 'about',
    component: () => import('@/views/about/index.vue'),
    meta: {
      name: "关于我们"
    }
  }]
}

const aboutUs = {
  path: '/aboutus',
  component: layout,
  children: [{
    path: '',
    name: 'aboutus',
    component: () => import('@/views/about/detail.vue'),
    meta: {
      name: "关于我们"
    }
  }]
}

const proposition = {
  path: '/proposition',
  component: layout,
  children: [{
    path: 'detail',
    name: 'propositiondetail',
    component: () => import('@/views/proposition/detail.vue'),
    meta: {
      name: "命题详情"
    }
  }]
}

const division = {
  path: '/division',
  component: layout,
  children: [{
    path: 'detail',
    name: 'divisiondetail',
    component: () => import('@/views/division/detail.vue'),
    meta: {
      name: "赛区详情"
    }
  }]
}

const review = {
  path: '/review',
  component: layout,
  children: [{
    path: '',
    name: 'review',
    component: () => import('@/views/review/index.vue'),
    meta: {
      name: "往届回顾"
    }
  }, {
    path: 'detail',
    name: 'detail',
    component: () => import('@/views/review/detail.vue'),
    meta: {
      name: "往届回顾详情"
    }
  }]
}

const account = {
  path: '/account',
  component: layout,
  children: [{
    path: 'login',
    name: 'login',
    component: () => import('@/views/account/login.vue'),
    meta: {
      name: "登录",
      header: true,
      footer: true
    }
  }, {
    path: 'register',
    name: 'register',
    component: () => import('@/views/account/register.vue'),
    meta: {
      name: "注册",
      header: true,
      footer: true
    }
  }, {
    path: 'resetAccount',
    name: 'resetAccount',
    component: () => import('@/views/account/resetAccount.vue'),
    meta: {
      name: "重设密码",
      header: true,
      footer: true
    }
  }]
}

const accountInformation = {
  path: '/accountInformation',
  component: layout,
  children: [{
    path: '',
    name: 'accountInformation',
    component: () => import('@/views/account-information/index.vue'),
    meta: {
      name: "敦煌国际设计周",
      header: true,
      footer: true
    }
  }]
}

const routes = [
  // {
  //   path: '/',
  //   redirect: '',
  // },
  nav,
  dynamic,
  certificate,
  work,
  expert,
  about,
  aboutUs,
  proposition,
  division,
  review,
  account,
  accountInformation
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return to.path === '/' ? '' : { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let list = ['/accountInformation']
  let token = getToken().token
  if (list.includes(to.path)) {
    if (token) {
      next()
    } else {
      next('/account/login')
    }
  } else {
    if (to.path === '/account/login') {
      if (token) {
        next('/accountInformation')
      } else {
        next()
      }
    } else {
      next()
    }

  }

})
export default router
