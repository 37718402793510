import request from '@/utils/request'

// 获取导航信息
export function getNavSelectAll(params) {
	return request({
		url: '/nav/selectAll',
		method: 'get',
		params
	})
}


// 获取合作品牌
export function getBrandSelect(params) {
	return request({
		url: `/coop/brand/select`,
		method: 'get',
		params,
	})
}

// 通用页面
export function getPageSelect(data) {
	return request({
		url: `/pageBase/select`,
		method: 'post',
		data,
	})
}

// 获取底部信息
export function getBottomGet(params) {
	return request({
		url: `/website/settings/get`,
		method: 'get',
		params,
	})
}


// 获取底部导航列表
export function getPageBaseBottomVo(params) {
	return request({
		url: `/pageBase/getPageBaseBottomVo`,
		method: 'get',
		params,
	})
}

// 查询轮播图
export function queryBannerSelect(params) {
	return request({
		url: `/zqAdSettings/select`,
		method: 'get',
		params,
	})
}

// 首页全部数据
export function getHomeContent(params) {
	return request({
		url: `/webSitePage/getHomeContent`,
		method: 'get',
		params,
	})
}

// 根据字典类型查询字典详情
export function getZqDictDetail(params) {
	return request({
		url: `/dict/getZqDictDetail`,
		method: 'get',
		params,
	})
}

// 文件上传
export function uploadFile(data) {
	return request({
		url: `/file/uploadFile`,
		method: 'post',
		data: {
			name: data
		}
	})
}
