<template>
	<footer>
		<footer class="company-info c w">
			<div class="left">
				<p>
					<span>{{ list.copyright }}</span>
					<span class="link">{{ websiteMsg.link }}</span>
				</p>
				<p>
					{{ list.txt }}
				</p>
			</div>
			<div class="right">
				<div class="img-box">
					<div class="img-cell img-w">
						<img :src="websiteMsg.wxServiceImg" alt="" />
						<p>微信服务号</p>
					</div>
					<div class="img-cell img-g">
						<img :src="websiteMsg.wxImg" alt="" />
						<p>官方公众号</p>
					</div>
				</div>
				<div class="copy-detail">
					<p>{{websiteMsg.copyrightFrom}}</p>
					<p class="cell-top">
						<span>公司地址：甘肃省酒泉市教煌市</span>
						<span>阳关中路1144号</span>
					</p>
					<p class="cell-top">
						<a :href="websiteMsg.recordNumberLink" target="_blank">{{websiteMsg.recordNumber}}</a>
					</p>
				</div>
				<!-- <div>
					<p>电话：{{websiteMsg.mobile}}</p>
				</div> -->
			</div>
		</footer>
	</footer>
</template>
<script setup>
	import {
		ref,
		reactive,
		computed
	} from "vue";
	import {
		useRouter
	} from "vue-router";
	import {
		getBottomGet,
		getPageBaseBottomVo
	} from "@/api/publish";
	import {
		useWebsite
	} from "@/pinia/index";
	import {
		storeToRefs
	} from "pinia";
	const route = reactive(useRouter());

	function toPage(url) {
		route.push(url);
	}

	function openBA(url) {
		window.open(url);
	}
	const {
		websiteMsg
	} = storeToRefs(useWebsite());
	console.log(websiteMsg.value.name,'websiteMsg');
	const list = reactive({
		copyright: "Copyright @ 2018-2028",
		link: "",
		txt: "All right reserved.",
		copyrightFrom: '',
		wxImg: '',
		gzh: "",
		mobile: '',
		recordNumber: '',
		recordNumberLink: '',
		address: ''
	});
</script>
<style lang='scss' scoped>
	footer {
		position: relative;
		background-color: #474a4a;
		font-size: 12px;
		color: #f4f4f4;
	}

	.company-info {
		display: flex;
		justify-content: space-between;
		padding-bottom: 63px;
		padding: 40px 0;

		.right {
			display: flex;
		}

		p {
			line-height: 1.5;
		}

		.link {
			margin-left: 20px;
		}

		.img-box {
			display: flex;
			font-size: 12px;
			margin: 0 50px;

			img {
				width: 80px;
			}

			p {
				text-align: center;
			}

			.img-g {
				margin-left: 30px;
			}
		}

		.copy-detail {
			.time {
				display: inline-block;
				margin-left: 10px;
			}

			.cell-top {
				margin-top: 20px;
			}

			a {
				color: #f4f4f4;
				text-decoration: none;
			}
		}
	}
</style>
