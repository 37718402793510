<template>
	<header class="flex-row-between w" :class="{ isBoxShadow: isBoxShadow }">
		<div class="contact-box">
			<img class="logo-img" @click="toPage('/')" :src="websiteMsg.logo" alt="" />
		</div>
		<nav>
			<div v-for="(item, index) in navList" :class="{ on: currentNav === item.url }" :key="index"
				@click="navClick(item)" class="flex-column-end">
				<span>{{ item.name }}</span>
				<span class="en">{{ item.enName }}</span>
			</div>
		</nav>
	</header>
</template>
<script setup>
	import {
		ref,
		reactive,
		computed,
		watch,
		toRef,
		onMounted,
		onBeforeUnmount,
	} from "vue";
	import {
		defineProps
	} from "vue";
	import {
		useRouter
	} from "vue-router";
	import {
		getNavSelectAll,
		getBottomGet
	} from "@/api/publish";
	import {
		useWebsite
	} from "@/pinia/index";
	import {
		storeToRefs
	} from "pinia";
	import scrollFun from "./scroll";
	const route = reactive(useRouter());

	const props = defineProps({
		color: {
			type: String,
			default: "#fff",
		},
	});

	function toPage(path) {
		route.push({
			path,
		});
	}
	onMounted(() => {
		window.addEventListener("scroll", handleScroll);
	});
	onBeforeUnmount(() => {
		window.removeEventListener("scroll", handleScroll);
	});

	const currentNav = ref("");

	function navClick(item) {
		if (item.url) {
			route.push({
				path: item.url,
				query: {
					point: item.point,
				},
			});
		}
	}

	const isBoxShadow = ref(false);

	function handleScroll() {
		let scrollTop =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop;
		if (scrollTop > 100) {
			isBoxShadow.value = true;
		} else if (scrollTop === 0) {
			isBoxShadow.value = false;
		}
	}

	const navList = ref(
		[{
				name: "首页",
				en: "HOME",
				url: "/",
			},
			{
				name: "大赛介绍",
				en: "INTRODUCE",
				url: "/nav/details",
				point: 1,
			},
			{
				name: "赛事动态",
				en: "DYNAMIC",
				url: "/dynamic",
			},
			{
				name: "参赛流程",
				en: "PROCESS",
				point: 6,
				url: "/nav/details",
			},
			{
				name: "证书查询",
				en: "CERTIFICATE",
				url: "/certificate",
			},
			{
				name: "作品展示",
				en: "WORKS",
				url: "/work/index",
			},
			{
				name: "往届回顾",
				en: "REVIEW",
				url: "/review"
			},
			{
				name: "专家库",
				en: "EXPERT",
				url: "/expert/index",
			},
			{
				name: "联系我们",
				en: "CONTACT",
				url: "/about",
			},
		]
	);
	getNavSelectAll().then((res) => {
		navList.value = res.data;
	});

	const {
		websiteMsg
	} = storeToRefs(useWebsite());


	watch(
		() => route.currentRoute.fullPath,
		(newVla, oldVal) => {
			const WhiteList = ["/nav/home"];
			if (!WhiteList.includes(newVla)) {
				currentNav.value = newVla;
			}
		}, {
			immediate: true,
			deep: true
		}
	);
</script>
<style lang='scss' scoped>
	@keyframes anim-height {
		from {
			height: 0;
		}

		to {
			height: 230px;
		}
	}

	.isBoxShadow {
		box-shadow: 0 3px 12px rgb(98 107 181 / 80%);
	}

	header {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1000;
		display: flex;
		align-items: center;
		padding: 0 calc(50% - 600px);
		min-width: 1200px;
		height: $headerHeight;
		color: #000;
		transition: linear 0.3s;
		background-color: #fff;

		.logo-img {
			position: relative;
			top: 5px;
			z-index: 8;
			width: 250px;
		}

		nav {
			display: flex;
			margin-left: 30px;

			>div {
				position: relative;
				top: 7px;
				margin-left: 30px;
				font-size: 16px;
				text-align: center;
				cursor: pointer;

				>span {
					display: inline-block;
					box-sizing: border-box;
				}

				.en {
					position: relative;
					padding-bottom: 2px;
					font-size: 12px;
					zoom: 0.7;
					margin-top: 5px;
				}

				&:hover {
					color: $themeColor;

					.en {
						&::after {
							content: "";
							position: absolute;
							bottom: -8px;
							display: inline-block;
							width: 100%;
							height: 2px;
							left: 50%;
							transform: translateX(-50%);
							background-color: $themeColor;
						}
					}

					// .menu-details-box {
					//   display: block;
					// }
				}
			}

			.on {
				>span {
					border-color: #fff;
				}
			}
		}
	}

	.is-top {
		box-shadow: 0 4px 15px rgb(0 0 0 / 20%);
		color: #333;
	}

	.contact-box {
		position: relative;
		cursor: pointer;

		&:hover {
			.gzh-box {
				animation: anim-height 1s;
				height: 230px;
			}
		}

		.gzh-box {
			position: absolute;
			top: 100px;
			z-index: -1;
			box-sizing: border-box;
			overflow: hidden;
			height: 0;
			font-size: 18px;
			text-align: center;
			background-color: #fff;

			>h3 {
				padding-top: 10px;
			}

			>img {
				width: 80%;
			}

			&::before {
				position: absolute;
				top: -96px;
				left: 50%;
				z-index: -3;
				width: 5px;
				height: 130px;
				transform: translateX(-50%);
				background-color: inherit;
				border-radius: 10px;
				content: "";
			}
		}
	}

	.menu-details-box {
		display: none;
		position: absolute;
		top: $headerHeight - 10px;
		left: calc(50% - 600px);
		box-sizing: border-box;
		padding: 30px;
		width: 1200px;
		background-color: #fff;
		box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.3);
		transition: all 1s;
		animation: opacityChange 1s;

		.details-list {
			display: flex;
			flex-wrap: wrap;

			>li {
				margin-bottom: 25px;
				box-sizing: border-box;
				width: 50%;
				cursor: pointer;
				text-align: left;

				.det-name {
					padding: 20px 20px;
				}

				&:hover {

					// transition: linear 0.5s;
					.det-name {
						background-color: #0766bc;

						>h3 {
							color: #fff;
						}

						>p {
							color: #fff;
							opacity: 0.7;
						}
					}
				}

				.other-list {
					padding-top: 20px;

					>li {
						font-size: 16px;
						line-height: 1.5em;
						padding: 10px 0 20px 20px;
						color: #333;

						&:hover {
							color: $hoverColor;
						}
					}
				}

				.det-name {
					>h3 {
						margin-bottom: 15px;
						font-size: 16px;
						line-height: 1.5em;
						font-weight: normal;
						color: #202020;
					}

					>p {
						margin-top: 5px;
						font-size: 14px;
						line-height: 1.5em;
						color: #808080;
					}
				}
			}
		}
	}
</style>
