<template>
	<router-view />
</template>
<script setup>
	import {
		getBottomGet
	} from "@/api/publish";
	import {
		useWebsite
	} from "@/pinia/index";
	const website = useWebsite();
	getBottomGet().then((res) => {
		website.websiteMsg = res.data;
		document.title = res.data.name
	});
</script>
<style lang="scss" src="./styles/list.scss">
</style>
<style lang="scss" src="./styles/animations.scss">
</style>
<style lang="scss" src="./styles/mixin.scss">
</style>
<style lang="scss" src="./styles/flex.scss">
</style>
<style lang="scss" src="./styles/font.scss" />
<style lang="scss">
	:root {
		--el-color-primary: #59af98 !important;
		--el-color-primary-light-3: #59af98 !important;
		--el-color-primary-dark-2: #59af98 !important;
		--el-color-primary-light-9: #f2fff3 !important;
		--el-color-primary-light-8: #59af98 !important;
	}

	.stroke {
		text-shadow: 6px 6px 10px blue;
	}

	body {
		font-size: 14px;
		background-color: #fff;
		color: #1a1a1a;
		margin: 0;
		padding: 0;
		
	}

	div {
		box-sizing: border-box;
	}

	.w {
		width: 1200px;
		min-width: 1200px;
	}
	ul {
		margin: 0;
		padding: 0;
	}

	.c {
		margin: 0 auto;
	}

	.banner-text {
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translateY(-50%);
		box-sizing: border-box;
		padding-right: 400px;
		width: 1200px;
		margin-left: -600px;
		z-index: 10;
		color: #fff;
		animation: opacityChange 3s;

		.banner-title {
			margin-bottom: 30px;
			font-size: 44px;
		}

		.banner-desc {
			margin-bottom: 70px;
			font-size: 18px;
			line-height: 1.7em;
		}

		.custom-btn {
			display: inline-block;
			align-items: center;
			justify-content: center;
			padding: 0 20px;
			height: 40px;
			line-height: 40px;
			border: 1px solid #fff;
			cursor: pointer;
		}
	}

	.ul-none {
		ul {
			list-style: none;
		}
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	.flex-between {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
	}

	.over-text1 {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.top-box {
		position: relative;
		margin-top: -150px;
		z-index: 10;
		background-color: #fff;
	}

	.over-text2 {
		overflow: hidden;
		display: -webkit-box !important;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		/*要显示的行数*/
		-webkit-box-orient: vertical;
	}

	.over-text3 {
		@extend .over-text2;
		-webkit-line-clamp: 3;
		/*要显示的行数*/
	}

	.over-text4 {
		@extend .over-text2;
		-webkit-line-clamp: 4;
		/*要显示的行数*/
	}

	.usually-box {
		padding: 0 calc(50% - 600px);
	}

	.banner-img {
		width: 100%;
		height: 100%;
	}

	.publish-desc {
		>p {
			font-size: 18px;
			line-height: 1.7em;
			color: #808080;
		}
	}

	.publish-menu {
		>ul {
			>li {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				box-sizing: border-box;
				padding: 35px 30px 60px;
				width: 285px;
				height: 360px;
				background-color: #fff;

				>h3 {
					margin: 29px 0 21px;
					width: 100%;
					font-size: 28px;
					font-weight: normal;
					text-align: center;
				}

				>img {
					height: 72px;
				}

				>p {
					font-size: 16px;
					line-height: 1.6em;
					color: #808080;
				}
			}
		}
	}

	.color-hover {
		cursor: pointer;

		&:hover {
			color: $hoverColor;
		}
	}

	.scale-hover {
		cursor: pointer;

		&:hover {
			animation: anim-scale 1s infinite alternate;
		}
	}

	.shadow-hover {
		&:hover {
			animation: anim-shadow 1s infinite alternate;
		}
	}

	.text-light-hover {
		&:hover {
			animation: anim-orange 1.5s ease-in-out infinite alternate;
		}
	}

	.text-light-red-hover {
		$color: red;

		&:hover {
			color: red;
			text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px $color,
				0 0 25px $color, 0 0 30px $color, 0 0 35px $color, 0 0 50px $color;
		}
	}

	.active-border {
		position: relative;
		z-index: 0;
		margin: 20px;
		border-radius: 10px;
		overflow: hidden;
		padding: 2rem;

		&:hover {
			&::before {
				animation: rotate 3s linear infinite;
			}
		}

		&::before {
			content: "";
			position: absolute;
			z-index: -2;
			left: -50%;
			top: -50%;
			width: 200%;
			height: 250%;
			background-color: #f1f3f8;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-image: conic-gradient(transparent,
					rgba(168, 239, 255, 1),
					transparent 50%);
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			left: 6px;
			top: 6px;
			width: calc(100% - 12px);
			height: calc(100% - 12px);
			background-color: #fff;
			border-radius: 5px;
		}
	}
</style>
