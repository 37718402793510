import {
	defineStore
} from 'pinia'
export const useWebsite = defineStore({
	id: 'website',
	state: () => ({
		websiteMsg: {}
	}),
	actions: {},
	getters:{
		getWebsiteMsg(state) {
			return state.websiteMsg
		}
	}
})
