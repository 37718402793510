
function IsPC(){  
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
  var flag = true;  
  for (var v = 0; v < Agents.length; v++) {  
      if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
  }  
  return flag;  
}

const ispc = IsPC()
// if(process.env.NODE_ENV !== 'development') {
//   if(ispc) {
//     if(window.location.href.indexOf('mobile') !== -1) {
//       window.location = 'https://www.zq-net.cn/#/'
//     }
//   } else {
//     if(window.location.href.indexOf('mobile') === -1) {
//       window.location = 'https://www.zq-net.cn/mobile/#/'
//     }
//   }
// }