import axios from 'axios'
import {
  ElMessage
} from 'element-plus'
import { getToken } from '@/utils/auth'
import router from '@/router'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://dunhuang.zqwl123.com/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 500000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // console.log(config,'config')
    if (getToken().token) {
      config.headers.Authorization = 'zq-website_' + getToken().token
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  /**
   * 1116 账号信息不存在
   */
  response => {
    const res = response.data
    if (res.code === 1112 || res.code === 1116) {
      ElMessage({
        message: res.msg,
        type: 'error',
      })
      localStorage.removeItem('zq-token')
      router.push({
        path: '/account/login'
      })
      return Promise.reject(new Error(`request ${res.msg || 'Error'}`))
    } else {
      if (res.code != 1000) {
        ElMessage({
          message: res.msg,
          type: 'error',
        })
        return Promise.reject(new Error(`request ${res.msg || 'Error'}`))
      } else {
        return res
      }
    }

  },
  error => { // 响应错误处理
    ElMessage({
      message: '网络异常，请重试',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
